import { Inject, Injectable, InjectionToken } from '@angular/core';

export const FACEBOOK_APP_ID = new InjectionToken<string>('FACEBOOK_APP_ID');

@Injectable({
  providedIn: 'root',
})
export class FacebookSdkService {
  private sdkLoaded = false;
  private sdkLoadingPromise: Promise<void> | null = null;

  constructor(@Inject(FACEBOOK_APP_ID) private appId: string) {}

  loadSdk(version: string = 'v12.0'): Promise<void> {
    if (this.sdkLoaded) {
      // SDK is already loaded and initialized
      return Promise.resolve();
    }

    if (this.sdkLoadingPromise) {
      // SDK is in the process of loading
      return this.sdkLoadingPromise;
    }

    // Initialize the SDK loading process
    this.sdkLoadingPromise = new Promise((resolve, reject) => {
      // Set up fbAsyncInit to initialize the SDK when the script loads
      (window as any).fbAsyncInit = () => {
        try {
          FB.init({
            appId: this.appId,
            cookie: true,
            xfbml: true,
            version: version,
          });
          FB.AppEvents.logPageView(); // Log page view
          this.sdkLoaded = true; // Mark SDK as loaded
          resolve();
        } catch (error) {
          reject('Facebook SDK initialization failed');
        }
      };

      const scriptId = 'facebook-jssdk';
      const existingScript = document.getElementById(scriptId);

      if (!existingScript) {
        // Dynamically load the SDK script
        const script = document.createElement('script');
        script.id = scriptId;
        script.src = 'https://connect.facebook.net/en_US/sdk.js';
        script.async = true;
        script.defer = true;

        script.onerror = () => reject('Failed to load Facebook SDK script');
        document.body.appendChild(script);
      } else if (this.sdkLoaded) {
        // SDK script already loaded and initialized
        resolve();
      } else {
        // SDK script is present but not yet initialized
        // Wait for fbAsyncInit to resolve
        (window as any).fbAsyncInit();
      }
    });

    return this.sdkLoadingPromise;
  }
}
