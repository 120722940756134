import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private readonly loading$ = new BehaviorSubject<boolean>(false);

  // keep a list of actions (UUIDs) that are in progress; loading is set to True until all the actions are complete
  private actionsInProgress = new Set<string>();

  watchLoading() {
    return this.loading$.asObservable();
  }

  startAction(actionId: string) {
    this.actionsInProgress.add(actionId);
    this.updateLoadingState();
  }

  endAction(actionId: string) {
    this.actionsInProgress.delete(actionId);
    this.updateLoadingState();
  }

  private updateLoadingState() {
    if (this.actionsInProgress.size === 0) {
      // all the actions are complete
      this.loading$.next(false);
    } else {
      // at least one action is in progress
      this.loading$.next(true);
    }
  }
}
