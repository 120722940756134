import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { environment } from './environments/environment';

import { AppComponent } from './app/app.component';

// TODO: reenable Sentry after implementing the custom error handler
if (false && environment.production) {
  enableProdMode();

  Sentry.init({
    dsn: 'https://752dcd8a2f79ba7b17e8c015fdf13fc7@o4507504494706688.ingest.us.sentry.io/4507504497721344',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    release: environment.version,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    beforeSend(event) {
      // You can customize the event here before sending it to Sentry
      // Returning null prevents the event from being sent
      return event;
    },
  });
}

bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));
