import { Routes } from '@angular/router';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { MasterAuthGuard } from 'src/app/guards/master-auth.guard';

export const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/locations' },
  {
    path: 'locations',
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/location/location.routes').then((m) => m.LOCATION_ROUTES),
  },
  {
    path: 'master',
    canActivate: [MasterAuthGuard],
    loadChildren: () => import('./features/master/master.routes').then((m) => m.MASTER_ROUTES),
  },
  {
    path: '',
    loadChildren: () => import('./features/public/public.routes').then((m) => m.PUBLIC_ROUTES),
  },
];
