import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { Store } from '@services/store';
import { CommonRoutes } from 'src/app/config/constants';

@Injectable({
  providedIn: 'root',
})
export class MasterAuthGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly store: Store,
  ) {}

  async canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    const user = await firstValueFrom(this.store.getUser());

    // firstly, check if user is authenticated at all
    if (!user) {
      // save the current route so we can redirect after login
      this.store.setInitialRouteAfterLogin(_state.url);

      void this.router.navigate([CommonRoutes.LoginPage]);
      return false;
    }

    // then, look for the "Master" role
    if (user?.masterRole) {
      return true;
    }

    void this.router.navigate([CommonRoutes.DashboardLandingPage]);
    return false;
  }
}
