import { CurrencyPipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customCurrency',
  standalone: true,
})
export class CustomCurrencyPipe implements PipeTransform {
  constructor(
    private currencyPipe: CurrencyPipe,
    @Inject(LOCALE_ID) private locale: string,
  ) {}

  transform(amount: number, currency: string): string {
    const isInteger = Number.isInteger(amount);
    const digitsInfo = isInteger ? '1.0-0' : '1.2-2';
    const formattedValue = this.currencyPipe.transform(amount, currency, 'symbol', digitsInfo, this.locale);

    return formattedValue || '';
  }
}
