const host = 'https://api.timetailor.com';

export const environment = {
  production: true,
  apiUrl: `${host}/`,
  bookingUrl: `https://booking.timetailor.com`,
  version: 'b6e530ade1d78c0f1004544901409e96083f66b1', // Placeholder for version
  name: 'production',
  uploadCarePublicKey: 'dcc918afd43d13903024',
  googleAuthClientKey: '891482607059-483hcpird9s85m18ndc4is031ls5voo5.apps.googleusercontent.com',
  facebookAppId: '1230886921516023',
  signupTypeformUrl: 'https://form.typeform.com/to/eyOKF0Hg',
  intercomAppId: 'v4smzmyf',
};
