import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ROUTE_MAPPINGS } from '../app.route-mappings';
import { filter } from 'rxjs/operators';
import { BreakpointPrefix, ScreenSizeService } from './screen-size.service';

@Injectable({
  providedIn: 'root',
})
export class RedirectionService {
  constructor(
    private router: Router,
    private screenSizeService: ScreenSizeService,
  ) {}

  public handleRedirection(): void {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      const currentRoute = event.urlAfterRedirects;

      if (this.screenSizeService.isScreenBelow(BreakpointPrefix.MD) && !this.isMobileRoute(currentRoute)) {
        const mobileRoute = this.getMobileRoute(currentRoute);
        if (mobileRoute) {
          this.router.navigate([mobileRoute]);
        }
      } else if (this.screenSizeService.isScreenAbove(BreakpointPrefix.MD) && !this.isDesktopRoute(currentRoute)) {
        const desktopRoute = this.getDesktopRoute(currentRoute);
        if (desktopRoute) {
          this.router.navigate([desktopRoute]);
        }
      }
    });
  }

  private isMobileRoute(route: string): boolean {
    return Object.keys(ROUTE_MAPPINGS).includes(route);
  }

  private isDesktopRoute(route: string): boolean {
    return Object.values(ROUTE_MAPPINGS).includes(route);
  }

  private getMobileRoute(desktopRoute: string): string | null {
    const mobileRoute = Object.keys(ROUTE_MAPPINGS).find((key) => ROUTE_MAPPINGS[key] === desktopRoute);
    return mobileRoute || null;
  }

  private getDesktopRoute(mobileRoute: string): string | null {
    return ROUTE_MAPPINGS[mobileRoute] || null;
  }
}
