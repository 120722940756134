import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { WindowService } from './window.service';
import { ThemeService } from './theme.service';
import { StorageKey } from './window.service';

type setThemeProp = 'setIsNightTheme' | 'setThemesMode';
type getThemeProp = 'getIsNightTheme' | 'getThemesMode';

interface InitThemeOption {
  storageKey: StorageKey;
  setMethodName: setThemeProp;
  getMethodName: getThemeProp;
}

@Injectable({
  providedIn: 'root',
})
export class InitThemeService {
  private themesService = inject(ThemeService);
  private windowService = inject(WindowService);

  themeInitOption: InitThemeOption[] = [
    {
      storageKey: StorageKey.IsNightKey,
      setMethodName: 'setIsNightTheme',
      getMethodName: 'getIsNightTheme',
    },
    {
      storageKey: StorageKey.ThemeOptionsKey,
      setMethodName: 'setThemesMode',
      getMethodName: 'getThemesMode',
    },
  ];

  initTheme(): Promise<void> {
    return new Promise((resolve) => {
      this.themeInitOption.forEach((item) => {
        const cachedData = this.windowService.getStorage(item.storageKey);
        if (cachedData) {
          this.themesService[item.setMethodName](JSON.parse(cachedData));
        } else {
          (this.themesService[item.getMethodName]() as Observable<NzSafeAny>).pipe(first()).subscribe((res) => {
            return this.windowService.setStorage(item.storageKey, JSON.stringify(res));
          });
        }
      });
      return resolve();
    });
  }
}
