import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { SubscribedComponent } from './shared/components/subscribed.component';
import { PreloaderService } from '@services/preloader.service';
import { filter, takeUntil } from 'rxjs';
import { AuthService } from '@services/auth/auth.service';
import { LoadingComponent } from 'src/app/shared/components/loading/loading.component';

@Component({
  selector: 'app-root',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, NzLayoutModule, NzSpinModule, RouterOutlet, LoadingComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent extends SubscribedComponent implements OnInit, AfterViewInit {
  title = 'Appointments Taking App';

  constructor(
    private readonly router: Router,
    private readonly preloader: PreloaderService,
    private readonly authService: AuthService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.scrollToTop();

    // check if user is (still) authenticated
    this.authService.getProfile(true).pipe(takeUntil(this.destroyed$)).subscribe();
  }

  scrollToTop(): void {
    this.router.events
      .pipe(
        takeUntil(this.destroyed$),
        filter((event) => event instanceof NavigationEnd),
      )
      .subscribe(() => {
        window.scrollTo(0, 0);
      });
  }

  ngAfterViewInit(): void {
    this.preloader.removePreLoader();
  }
}
