import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, filter, finalize, Observable, switchMap, take, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '@services/auth/auth.service';
import { CommonRoutes } from 'src/app/config/constants';
import { Router } from '@angular/router';
import { CustomHttpHeaderName, HttpRequestScope } from 'src/app/types/http.types';
import { ApiErrorCode } from 'src/app/types/api-error.types';
import { StorageKey, WindowService } from '@services/window.service';
import { LoadingService } from 'src/app/shared/components/loading/loading.service';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  private isRefreshing: boolean = false;
  private newAccessToken$ = new BehaviorSubject<string | null>(null);

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly windowService: WindowService,
    private readonly loadingService: LoadingService,
  ) {}

  intercept(request: HttpRequest<object>, next: HttpHandler): Observable<HttpEvent<object>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        switch (error.status) {
          case 401:
            return this.handle401Error(error, request, next);
          case 500:
            // #TODO show snackbar (Internal Server Error)
            break;
          case 404:
            // #TODO show snackbar (Resource Not Found)
            break;
          case 403:
            // #TODO show snackbar (Forbidden Action)
            break;
        }

        // pass the error on to the next layer
        return throwError(() => error);
      }),
      finalize(() => {
        // update the global loading state
        const requestId = request.headers.get(CustomHttpHeaderName.REQUEST_ID);
        if (requestId) {
          this.loadingService.endAction(requestId);
        }
      }),
    );
  }

  private handle401Error(error: HttpErrorResponse, request: HttpRequest<object>, next: HttpHandler) {
    const requestScope = request.headers.get(CustomHttpHeaderName.REQUEST_SCOPE);

    if (requestScope === HttpRequestScope.REFRESH_TOKEN) {
      // the Refresh Token API request failed with 401
      // logout and redirect to the Login page
      void this.authService.logout();
      void this.router.navigate([CommonRoutes.LoginPage]);

      return throwError(() => error);
    } else if (requestScope === HttpRequestScope.LOGOUT) {
      // the Logout API request failed with 401
      // do nothing, stop here
      return throwError(() => error);
    }

    const refreshToken = this.windowService.getStorage(StorageKey.RefreshToken);

    if (error.error?.errorCode === ApiErrorCode.TOKEN_UNAUTHORIZED && refreshToken) {
      // need to refresh the token; check if token refresh is already in progress
      if (!this.isRefreshing) {
        this.isRefreshing = true;
        this.newAccessToken$.next(null);

        // refresh the access token
        return this.authService.refreshToken().pipe(
          switchMap((result) => {
            this.isRefreshing = false;
            this.newAccessToken$.next(result.accessToken);

            // retry the request using the new access token
            return next.handle(this.addAuthTokenHeader(request, result.accessToken));
          }),
          catchError((error) => {
            this.isRefreshing = false;

            return throwError(() => error);
          }),
        );
      }

      return this.newAccessToken$.pipe(
        filter((token) => token !== null),
        take(1),
        switchMap((token) => next.handle(this.addAuthTokenHeader(request, token))),
      );
    }

    if (requestScope !== HttpRequestScope.CHECK_AUTH_STATUS) {
      // something else failed; logout and redirect to the Login page
      void this.authService.logout();
      void this.router.navigate([CommonRoutes.LoginPage]);
    } else {
      // do nothing; we are just checking if the user is authenticated
    }

    return throwError(() => error);
  }

  private addAuthTokenHeader(request: HttpRequest<unknown>, accessToken: string) {
    return request.clone({ headers: request.headers.set('Authorization', `Bearer ${accessToken}`) });
  }
}
